import Heading from "@/components/Elements/Heading";
import React, { useContext, useEffect, useReducer } from "react";
import RegisterEmail from "./Email/RegisterEmail";
import RegisterBankId from "./BankId";
import toast from "react-hot-toast";
import Alert from "@/components/Elements/Alert";
import ArrowButton from "@/components/Elements/ArrowButton";
import { AuthenticationContext } from "../Wrapper";

export const RegisterContext = React.createContext<any>(null);

const RegisterWrapper: React.FC = () => {
  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);

  const [state, dispatch] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case "SET_VIEW":
          return { ...state, view: action.payload };
        case "SET_STEP":
          return { ...state, step: action.payload };
        case "SET_MAX_STEP":
          return { ...state, maxStep: action.payload };
        case "SET_USER_REGISTERED":
          return { ...state, isUserRegistered: action.payload };
        default:
          return state;
      }
    },
    {
      view: "bankid",
      isUserRegistered: false,
      step: {
        currentStep: 1,
        info: "För att kunna skapa ett konto krävs verifiering via BankID",
      },
      maxStep: 3,
    }
  );

  const view = state.view;

  return (
    <>
      {view == "bankid" && (
        <Heading size="lg" className="mb-4">
          Registrera konto via BankId
          <span className="!font-light block md:inline-block text-xl md:text-2xl">
            {" "}
            <span className="hidden md:inline-block">(</span>Steg{" "}
            {state?.step?.currentStep} av {state?.maxStep}
          </span>
          <span className="hidden md:inline-block font-light text-xl md:text-2xl">
            )
          </span>
          {state?.step?.info && (
            <span className="font-light text-16 mt-2 mb-4 block">
              {state?.step?.info}
            </span>
          )}
        </Heading>
      )}

      {view == "email" && (
        <>
          <Heading size="lg" className="mb-4">
            Register new international account
          </Heading>

          <Alert
            className="w-fit my-4 bg-aliceblue-dark text-modal-blue mb-6"
            variant="outlined"
            icon={
              <svg
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                strokeWidth={2}
                stroke="currentColor"
                className="h-6 w-6"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
                />
              </svg>
            }
          >
            <h3 className="text-xl mb-2">Svensk kund?</h3>
            Om du är svensk kund och vill öppna ett konto, vänligen registrera
            via BankID genom att klicka på länken nedan.
            <div className="mt-4">
              <ArrowButton
                text="Registrera via BankID"
                direction="right"
                onClick={() =>
                  LoginStateDispatch({ type: "SET_SHOW", payload: "register" })
                }
              />
            </div>
          </Alert>
        </>
      )}

      <RegisterContext.Provider value={{ state, dispatch }}>
        <div className="flex flex-col">
          {view == "bankid" && <RegisterBankId />}
          {view == "email" && <RegisterEmail />}
        </div>
      </RegisterContext.Provider>
    </>
  );
};

export default RegisterWrapper;
