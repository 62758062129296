import ButtonToggle from "@/components/Elements/ButtonToggle";
import LoginEmail from "./Email";
import Heading from "@/components/Elements/Heading";
import React, { useEffect } from "react";
import { AuthenticationContext } from "../Wrapper";
import BankIdChooseAccount from "./BankIdChooseAccount";
import BankIdWrapper from "@/components/BankId";
import ArrowButton from "@/components/Elements/ArrowButton";
import Link from "next/link";
import useModalStore from "@/hooks/useModalStore";

const LoginWrapper: React.FC = () => {
  const { state, dispatch } = React.useContext(AuthenticationContext);
  const { loginModal } = useModalStore();

  const menuLinks = [
    {
      label: "Bank ID",
      onClick: () => dispatch({ type: "SET_LOGIN_METHOD", payload: "bankid" }),
      selected: state?.loginMethod === "bankid",
    },
    {
      label: "Användarnamn",
      onClick: () => dispatch({ type: "SET_LOGIN_METHOD", payload: "email" }),
      selected: state?.loginMethod === "email",
    },
  ];

  const loginMethod = state?.loginMethod;

  return (
    <div className="w-full">
      {!state.hideTitles && (
        <Heading size="lg" className="mb-6">
          {state.loginTitle || "Logga in"}{" "}
        </Heading>
      )}
      <div className="flex flex-col">
        {state?.isBarVisible ? (
          <div className="mb-6">
            <ButtonToggle buttons={menuLinks} useMobileVersion={false} />
          </div>
        ) : null}
        <div className="self-stretch flex flex-row items-start justify-start">
          <div className="flex-1 relative leading-[150%]">
            {loginMethod === "email" ? (
              <LoginEmail />
            ) : (
              <BankIdWrapper
                bankIdOptions={{
                  setBankIDSecret: true,
                }}
                successComponent={BankIdChooseAccount}
                bankIdTitle="Vid inloggning med Mobilt BankID på annan enhet."
                bankIdDescription="På grund av säkerhetsskäl har du 30 sekunder på dig att läsa av
              QR-koden."
              />
            )}
          </div>
        </div>
        {!state.isLoginModal && (
          <div className="flex flex-col mt-6 space-y-4 md:mt-8 bg-aliceblue p-5 text-toast-blue rounded-lg">
            Har du inget konto?{" "}
            <ArrowButton
              extraClasses="text-lg md:text-base"
              text="Skapa konto"
              direction="right"
              onClick={() =>
                dispatch({ type: "SET_SHOW", payload: "register" })
              }
            />
          </div>
        )}

        {state.isLoginModal && (
          <div className="flex flex-col space-y-4 bg-aliceblue p-5 text-toast-blue rounded-lg mt-6">
            Har du inget konto?{" "}
            <Link href="/mina-sidor/?skapa-konto" className="!no-underline">
              <ArrowButton
                text="Skapa konto"
                direction="right"
                onClick={() => {
                  // Close modal
                  loginModal.onClose();
                }}
              />
            </Link>
          </div>
        )}
      </div>
    </div>
  );
};

export default LoginWrapper;
