import BankIdWrapper from "@/components/BankId";
import React, { useContext } from "react";
import Authenticated from "./Authenticated";
import { AuthenticationContext } from "../../Wrapper";
import { RegisterContext } from "..";
import ArrowButton from "@/components/Elements/ArrowButton";
import useSWR from "swr";

const BankIDRegister = () => {
  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);
  const { dispatch: RegisterStateDispatch } = useContext(RegisterContext);

  return (
    <div className="self-stretch flex flex-col items-start justify-start">
      <BankIdWrapper successComponent={Authenticated} />

      <div className="flex flex-col w-full space-y-4 mt-8 bg-aliceblue p-5 text-toast-blue rounded-lg">
        <div>
          Har du redan ett konto?{" "}
          <ArrowButton
            text="Gå till inloggning"
            direction="right"
            onClick={() =>
              LoginStateDispatch({ type: "SET_SHOW", payload: "login" })
            }
          />
        </div>
        <div>
          Don&apos;t have a swedish personal identity number?{" "}
          <ArrowButton
            text="Register via email"
            direction="right"
            onClick={() =>
              RegisterStateDispatch({ type: "SET_VIEW", payload: "email" })
            }
          />
        </div>
      </div>
    </div>
  );
};

export default BankIDRegister;
