"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Checkbox from "@/components/Elements/Checkbox";
import Button from "@/components/Elements/Button";
import {
  getLatestTermsForLoggedOutUser,
  regiserUserInternational,
} from "@/actions/user";
import toast from "react-hot-toast";
import Row from "../Row";
import { validatePassword, validateUsername } from "@/lib/user";
import { AuthenticationContext } from "../../Wrapper";
import ArrowButton from "@/components/Elements/ArrowButton";
import { getErrorCodeMessage } from "@/lib/reporting";
import useSWR from "swr";
import ReactHtmlParser from "html-react-parser";
import Alert from "@/components/Elements/Alert";
import BeatLoader from "react-spinners/BeatLoader";
import countries from "world-countries";
import ModalElement from "@/components/Modal/Modal";
import "react-loading-skeleton/dist/skeleton.css";

const RegisterEmailView = () => {
  const [isSubmitting, setisSubmitting] = useState(false);
  const [isAccountCreated, setIsAccountCreated] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [inputFields, setInputFields] = useState<any>({});

  // country.translations.swe?.common
  const europeanCountries = countries
    .filter((country) => country.region === "Europe")
    .map((country) => ({
      label: country.name.common,
      value: country.cca2,
    }))
    .sort((a, b) => a.label.localeCompare(b.label))
    .filter((country) => country.value !== "SE");

  const {
    data: agreementData,
    error,
    isLoading,
  } = useSWR<any, Error>(
    ["api/getTerms"],
    async () => {
      const agreementData = await getLatestTermsForLoggedOutUser();
      return agreementData?.find(
        (agreement: any) => agreement.textLangId === 2
      );
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  const inputFieldsList = {
    userRow: [
      {
        key: "userType",
        label: "Customer Type",
        type: "select",
        validateProps: {},
        canEdit: true,
        options: [
          { label: "Company", value: "company" },
          { label: "Personal", value: "privatePerson" },
        ],
      },
      {
        key: "username",
        label: "Username",
        type: "text",
        validateProps: {
          validate: (value: any) =>
            validateUsername(value) ||
            "Username can only contain letters (not including tovek), numbers, and special characters (_-) and be between 3-50 characters long",
        },
        canEdit: true,
        required: true,
      },
      {
        key: "password",
        label: "Password",
        type: "password",
        required: true,
        validateProps: {
          validate: (value: any) =>
            validatePassword(value) ||
            "Password must be between 8 and 120 characters long and contain two of the following: uppercase letter, lowercase letter, number, special character",
        },
        canEdit: true,
      },
      {
        key: "password2",
        label: "Confirm Password",
        type: "password",
        required: true,
        validateProps: {
          required: "You must confirm your password.",
          validate: (value: any) => {
            const match = watch("password") === value;
            return match || "Passwords do not match.";
          },
        },
        canEdit: true,
      },
    ],
    contactInfo: [
      {
        key: "userEmail",
        label: "Email",
        type: "email",
        required: true,
        validateProps: {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Invalid email address",
          },
        },
        canEdit: true,
      },
      {
        key: "infoCellPhone",
        label: "Mobile",
        type: "text",
        required: false,
        canEdit: true,
        validateProps: {},
      },
      {
        key: "infoFirstname",
        label: "First Name",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      },
      {
        key: "infoSurname",
        label: "Last Name",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      },
    ],
    addressInfo: [
      {
        key: "infoAddress",
        label: "Street Address",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      },
      {
        key: "infoZipCode",
        label: "Postal Code",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      },
      {
        key: "infoCity",
        label: "City",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: true,
      },
      {
        key: "infoCountryCode",
        label: "Country",
        type: "select",
        options: europeanCountries,
        required: true,
        validateProps: {},
        canEdit: true,
      },
    ],
  };

  const {
    control,
    trigger,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      userType: "company",
    },
  });

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [isAccountCreated]);

  useEffect(() => {
    setInputFields(inputFieldsList);
  }, []);

  const userType = watch("userType");

  let getUserType = userType;

  if (getUserType === "Privatperson") {
    getUserType = "privatePerson";
  } else if (getUserType === "Företag") {
    getUserType = "company";
  }

  useEffect(() => {
    if (getUserType === "company") {
      setInputFields((prev: any) => ({
        ...prev,
        contactInfo: [
          ...inputFieldsList.contactInfo,
          {
            key: "infoName",
            label: "Company Name",
            type: "text",
            required: true,
            validateProps: {},
            canEdit: true,
          },
          {
            key: "infoVatNo",
            label: "Company VAT",
            type: "text",
            required: false,
            validateProps: {},
            canEdit: true,
          },
        ],
      }));
    }

    if (getUserType === "privatePerson") {
      setInputFields((prev: any) => ({
        ...prev,
        contactInfo: inputFieldsList.contactInfo,
      }));

      reset({
        ...getValues(),
        infoVatNo: undefined,
        infoName: undefined,
      });
    }
  }, [getUserType, reset, getValues]);

  const onSubmit: SubmitHandler<FieldValues> = async (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    setisSubmitting(true);

    try {
      const response = (await regiserUserInternational(data)) as any;

      if (!response || response?.status == "error") {
        toast.error(
          getErrorCodeMessage(
            response?.errorCode.toString(),
            null,
            "We could not verify your information, if you are sure you have entered the correct information, please try again later. Contact us if the problem persists.",
            false
          ),
          {
            id: "form-error-toast",
          }
        );
        return;
      }

      if (!response?.created) {
        throw new Error("An error occurred while creating the account.");
      }

      toast.success("Account created successfully.", {
        id: "form-success-toast",
      });

      setIsAccountCreated(true);

      // Login
    } catch (err) {
      toast.error(
        "Could not create account, please try again or contact us if the problem persists.",
        {
          id: "form-error-toast",
        }
      );
    } finally {
      setisSubmitting(false);
    }
  };

  const validateForm = async (e: any) => {
    e.preventDefault();
    const isValid = await trigger();

    // Get errors
    const allErrors = Object.entries(errors).map(([key, value]) => {
      return {
        key: key,
        type: value?.type,
        message: value?.message,
      };
    }) as any;

    const hasRequiredFields = allErrors.some(
      (error: any) => error.type === "required"
    );

    if (isValid) {
      handleSubmit(onSubmit)(e);
    } else if (hasRequiredFields) {
      toast.error("Please fill in all required fields.", {
        id: "form-error-toast",
      });
    } else {
      toast.error(
        allErrors[0].message || "Please fill in all fields correctly."
      );
    }
  };

  const hasAgreed = watch("register.hasAgreed", false);

  const handleToggle = (field: any) => {
    setValue(field, !watch(field)); // Toggle the value
  };

  if (isLoading) {
    <div className="flex justify-center items-center w-full">
      <BeatLoader color="#183e4f" />
    </div>;
  }

  return (
    <div className="w-full">
      <ModalElement
        isOpen={isAgreementModalOpen}
        size="xl"
        modalBg="bg-white"
        modalTitle={agreementData?.titleTextContent || "Registreringsavtal"}
        modalBody={
          <>
            <div className="max-h-[500px] overflow-x-hidden overflow-y-scroll relative pb-8 min-h-[500px] bg-gray-200 p-4">
              {agreementData?.contentTextContent &&
                ReactHtmlParser(agreementData?.contentTextContent)}
              <div className="shadow-scroll-gray sticky bottom-0"></div>
            </div>
            <div className="mt-8 flex justify-center items-center space-x-6">
              <Button
                className="p-4 bg-blue font-semibild text-white justify-center text-md"
                borderColor="blue"
                onClick={() => {
                  setValue("register.hasAgreed", true);
                  setIsAgreementModalOpen(false);
                }}
              >
                Accept the terms
              </Button>
              <Button
                className="p-4 bg-transparent font-semibild text-blue justify-center text-md"
                borderColor="blue"
                onClick={() => {
                  setIsAgreementModalOpen(false);
                }}
              >
                Cancel
              </Button>
            </div>
          </>
        }
        onClose={() => setIsAgreementModalOpen(false)}
      />

      <div className="flex flex-col">
        {isAccountCreated ? (
          <Alert className="flex flex-col mt-5 bg-success-green text-white">
            <h3 className="text-5xl font-semibold text-white mb-4">
              Thank you for registering
            </h3>

            <p className="text-white">
              Your account will be manually verified by our team and you will be
              able to log in once your account has been verified, we will
              contact you on the email address you provided.
            </p>
          </Alert>
        ) : (
          <form
            ref={formRef}
            onSubmit={validateForm}
            className="flex flex-col w-full"
          >
            <div className="p-6 bg-aliceblue text-slate-gray-dark font-light rounded-lg flex flex-col !mb-10">
              <h3 className="m-0">Account Information</h3>

              <div className="flex flex-col">
                <div className="flex flex-col flex-grow">
                  <div className="flex flex-col sm:space-y-4">
                    <Row
                      fields={inputFields?.userRow}
                      title="Account Information"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-grow">
                  <h3 className="mt-4 text-xl hidden sm:block">
                    Contact Information
                  </h3>
                  <div className="flex flex-col sm:space-y-4">
                    <Row
                      fields={inputFields?.contactInfo}
                      title="Contact Information"
                      register={register}
                      errors={errors}
                    />
                  </div>
                </div>

                <div className="flex flex-col flex-grow">
                  <h3 className="mt-4 text-xl hidden sm:block">Address</h3>
                  <div className="flex flex-col sm:space-y-4">
                    <Row
                      fields={inputFields?.addressInfo}
                      title="Address"
                      register={register}
                      errors={errors}
                      control={control}
                    />
                  </div>
                </div>
              </div>
              <div className="flex flex-col">
                <span className="my-4">* required field</span>
                <div className="flex flex-row items-center space-x-3 text-16">
                  <Checkbox
                    {...register("register.hasAgreed", {
                      required: "You must agree to the terms and conditions.",
                    })}
                    ripple={false}
                    required={true}
                    onChange={() => handleToggle("register.hasAgreed")}
                    checked={hasAgreed}
                  />
                  <label htmlFor="register.hasAgreed" className="text-sm">
                    I have read the{" "}
                    {agreementData?.contentTextContent ? (
                      <span
                        className="font-bold underline"
                        role="button"
                        onClick={() => setIsAgreementModalOpen(true)}
                      >
                        terms and conditions
                      </span>
                    ) : (
                      "terms and conditions"
                    )}{" "}
                  </label>
                </div>

                <Button
                  className="w-fit mt-8 ml-auto"
                  bgColor="blue"
                  type="submit"
                  onClick={() => trigger()}
                  disabled={isSubmitting}
                >
                  Register account
                </Button>
              </div>
            </div>
          </form>
        )}

        <div className="flex flex-col space-y-4 mt-8 bg-aliceblue p-5 text-toast-blue rounded-lg">
          <div>
            Already registered?{" "}
            <ArrowButton
              text="Go to login"
              direction="right"
              onClick={() =>
                LoginStateDispatch({ type: "SET_SHOW", payload: "login" })
              }
            />
          </div>
          <div>
            Swedish citizen?{" "}
            <ArrowButton
              text="Go to BankID registration"
              direction="right"
              onClick={() =>
                LoginStateDispatch({ type: "SET_SHOW", payload: "register" })
              }
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default RegisterEmailView;
