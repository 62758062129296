import useSWR from "swr";
import { userValidateBankIdToken } from "@/actions/user";

const useValidateBankIdToken = (pin: string, secret: string) => {
    const { data, error, isLoading } = useSWR<any, Error>(
        pin ? ["api/userValidateToken", pin] : null, // Ensure SWR is only called if `pin` is defined
        async () => {
            const response = await userValidateBankIdToken(pin, secret);
            return response;
        },
        {
            refreshInterval: 10 * 1000, // Poll every 10 seconds
            revalidateOnFocus: false, // Avoid revalidation on focus
        }
    );

    return { data, error, isLoading };
};

export default useValidateBankIdToken;