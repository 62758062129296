"use client";

import Alert from "@/components/Elements/Alert";
import { useContext, useEffect, useRef, useState } from "react";
import BeatLoader from "react-spinners/BeatLoader";
import Image from "next/image";
import FadeLoader from "react-spinners/FadeLoader";
import MethodFooter from "@/components/BankId/MethodFooter";
import { AccountContext } from "../context/AccountContext";
import { BankIdRegisterContext } from "../context/BankIdRegisterContext";

const RegisterAuthenticationView = () => {
  const { dispatch: AccountContextDispatch } = useContext(AccountContext);
  const { state, dispatch } = useContext(BankIdRegisterContext);
  const lastOpened = useRef<{ token: string | null; method: string | null }>({
    token: null,
    method: null,
  });

  const {
    qrCodeImage,
    identification,
    hasTimedOut,
    hintCode,
    autoStartToken,
    retriggerAuth,
    reset,
    authenticationMethod,
    hasError,
  } = state;

  const [componentMethod, setComponentMethod] = useState(authenticationMethod);

  useEffect(() => {
    retriggerAuth();
  }, []);

  const openQRCode = () => {
    setComponentMethod("qr");
    //retriggerAuth();
  };

  const openAutoStart = () => {
    setComponentMethod("auto");
    //retriggerAuth();
  };

  const goToRegister = () => {
    AccountContextDispatch({ type: "SET_VIEW", payload: "register" });
    reset();
  };

  useEffect((): void => {
    if (!autoStartToken || componentMethod !== "auto") {
      return;
    }

    // Prevent the autoStartToken from being opened twice on the same device
    if (lastOpened?.current?.token === autoStartToken) {
      return;
    }

    const url = `bankid:///?autostarttoken=${autoStartToken}&redirect=null`;

    window.open(url, "_self");
  }, [autoStartToken, componentMethod]);

  const statusTitle = hasTimedOut
    ? "BankID verifikation tog för lång tid"
    : hasError
    ? "Kunde inte verifiera BankID"
    : "";

  const cancelAction = () => {
    AccountContextDispatch({ type: "SET_VIEW", payload: "register" });
  };

  return (
    <div className="w-full">
      {(hasTimedOut && !identification) || hasError ? (
        <Alert
          variant="outlined"
          color="red"
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          }
        >
          <div className="flex flex-col space-y-4 my-8">
            <h3 className="text-xl">{statusTitle}</h3>
            <>
              Försök igen genom att klicka på knappen nedan. Om problemet
              kvarstår, pröva en annan enhet.
            </>
            <a
              href="https://install.bankid.com/"
              target="_blank"
              rel="noreferrer"
            >
              Installera BankID säkerhetsapp
            </a>
          </div>
        </Alert>
      ) : (
        <div>
          {componentMethod === "auto" && !hasTimedOut && (
            <div className="flex flex-col justify-center items-center space-y-4 my-8">
              <FadeLoader color="#183e4f" />
              <span className="fs-16">
                {" "}
                {["userSign", "started"].includes(hintCode) ? (
                  <>Väntar på signering i BankID-appen ...</>
                ) : (
                  <>Startar BankID appen ..</>
                )}
              </span>
            </div>
          )}
          {componentMethod === "qr" && !qrCodeImage ? (
            <div className="flex justify-center">
              <BeatLoader color="#183e4f" />
            </div>
          ) : componentMethod === "qr" ? (
            <div className="bg-aliceblue px-6 py-8 relative text-center rounded-lg">
              {hintCode === "userSign" ? (
                <div className="flex justify-center flex-col items-center space-y-4">
                  <span> Väntar på signering i BankID-appen ...</span>
                  <BeatLoader color="#183e4f" />
                </div>
              ) : (
                <>
                  <div className="flex justify-center items-center relative">
                    <svg
                      width="17"
                      height="16"
                      viewBox="0 0 17 16"
                      fill="none"
                      xmlns="http://www.w3.org/2000/svg"
                      className="absolute left-0 cursor-pointer transition-all duration-200 hover:-translate-x-[2px] hidden md:block"
                      onClick={cancelAction}
                    >
                      <path
                        d="M4.325 9L9.925 14.6L8.5 16L0.5 8L8.5 0L9.925 1.4L4.325 7H16.5V9H4.325Z"
                        fill="#2D2D32"
                      />
                    </svg>

                    <h2
                      className="text-center font-semibold"
                      onClick={openQRCode}
                    >
                      Starta BankID-appen
                    </h2>
                  </div>

                  <p className="text-profile-gray-dark font-light text-16 text-center my-6">
                    Starta Bank-id appen och tryck på QR-ikonen. Läs sedan av
                    den här QR-koden.
                  </p>

                  <Image
                    src={qrCodeImage}
                    className="mx-auto my-8"
                    alt="QR Code"
                    width={150}
                    height={150}
                  />

                  <span
                    className="text-blue font-semibold cursor-pointer"
                    onClick={openAutoStart}
                    role="button"
                  >
                    Öppna BankId på den här enheten istället
                  </span>
                </>
              )}
            </div>
          ) : null}
        </div>
      )}

      <MethodFooter
        hasError={hasError}
        hasTimedOut={hasTimedOut}
        reTriggerView={authenticationMethod}
        cancelAction={cancelAction}
        tryAgainAction={() => retriggerAuth()}
      />
    </div>
  );
};

export default RegisterAuthenticationView;
