import Button from "@/components/Elements/Button";
import React, { useContext, useEffect, useRef, useState } from "react";
import { AuthenticationContext, MaskPasswordIcon } from "./Wrapper";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import Input from "@/components/Elements/Input";
import Alert from "@/components/Elements/Alert";
import { forgotPass } from "@/actions/user";
import { colors } from "@material-tailwind/react/types/generic";
import Heading from "@/components/Elements/Heading";
import classNames from "classnames";
import ArrowButton from "@/components/Elements/ArrowButton";
import LinkWrapper from "@/components/Elements/LinkWrapper";

const ForgotPasswordWrapper: React.FC = () => {
  const { dispatch } = useContext(AuthenticationContext);
  const { state } = React.useContext(AuthenticationContext);

  const formRef = useRef<HTMLFormElement>(null);

  const [returnText, setReturnText] = useState<{ color: colors; text: string }>(
    {
      color: "green",
      text: "",
    }
  );

  const [isSubmitted, setIsSubmitted] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const {
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();
    setIsLoading(true);

    const { identifier } = data;

    (async () => {
      const resp = await forgotPass(identifier);
      setIsSubmitted(true);

      if (resp) {
        setReturnText({
          color: "green",
          text: "Ett mail har skickats till din e-postadress med instruktioner för att återställa ditt lösenord.",
        });
      } else {
        setReturnText({
          color: "red",
          text: "Ett fel uppstod, vänligen försök igen senare.",
        });
      }

      setIsLoading(false);
    })();
  };

  const maybeSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      formRef.current?.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  const hasErrors = Object.keys(errors).length !== 0;

  const Reset = () => {
    const resetClass = classNames("flex flex-col gap-4", {
      "opacity-50 animate-pulse": isLoading,
    });

    return (
      <div className={resetClass}>
        <div className="flex flex-col lg:flex-row w-full h-full gap-[12px]">
          <div className="flex-1 relative">
            <Input
              id="identifier"
              label="identifier"
              type="text"
              placeholder="Ditt användarnamn / e-postaddress"
              inputClassName="!text-base py-4 px-5 !h-full"
              className="!w-full relative !h-full"
              disabled={isSubmitted}
              error={errors.identifier !== undefined}
              onKeyUp={(e) => maybeSubmit(e)}
              {...register("identifier", {
                required: "Ange ett användarnamn/e-postaddress",
              })}
            />
            {errors.identifier && (
              <span className="text-red-400 text-12 absolute mt-3 -bottom-[25px] left-0">
                {errors.identifier.message?.toString()}
              </span>
            )}
          </div>
          <Button
            disabled={isSubmitted || hasErrors}
            bgColor="blue"
            type="submit"
            isLoading={isLoading}
          >
            Återställ
          </Button>
        </div>
      </div>
    );
  };

  const Submitted = ({ color, text }: { color: colors; text: string }) => {
    return (
      <div className="flex flex-col gap-4">
        <Alert
          color={color}
          icon={
            <svg
              xmlns="http://www.w3.org/2000/svg"
              fill="none"
              viewBox="0 0 24 24"
              strokeWidth={2}
              stroke="currentColor"
              className="h-6 w-6"
            >
              <path
                strokeLinecap="round"
                strokeLinejoin="round"
                d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
              />
            </svg>
          }
        >
          {text}
        </Alert>
      </div>
    );
  };

  return (
    <form
      ref={formRef}
      onSubmit={handleSubmit(onSubmit)}
      className="mx-auto md:max-w-md"
    >
      <div className="flex flex-col space-y-4 mb-4 items-center">
        {!state.hideTitles && (
          <Heading size="md">
            {state.resetPasswordTitle || "Återställ lösenord"}
          </Heading>
        )}
        <div>
          Ange din e-post eller användarnamn så skickar vi ett mail med
          instruktioner.
        </div>
      </div>

      {isSubmitted ? <Submitted {...returnText} /> : <Reset />}

      <div className="my-4"></div>

      <ArrowButton
        text="Tillbaka till login"
        onClick={() => dispatch({ type: "SET_SHOW", payload: "login" })}
      />
    </form>
  );
};

export default ForgotPasswordWrapper;
