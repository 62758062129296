import useBankId from "@/hooks/useBankId";
import Button from "@/components/Elements/Button";
import React, { useContext, useEffect, useMemo, useReducer } from "react";
import { BankIdContext } from "./BankIdContext";
import AuthenticationView from "./methods/AuthenticationView";

interface BankIdWrapperProps {
  successComponent?: React.FC<{
    identification: any;
    bankId: any;
  }>;
  onAuthenticated?: any;
  bankIdTitle?: string;
  bankIdDescription?: string;
  bankIdOptions?: BankIdOptions;
  debug?: boolean;
}

interface BankIdOptions {
  userVisibleData?: string;
  bankIdMethod?: "sign" | "auth";
  setBankIDSecret?: boolean;
}

const identificationDebug = false;

export default function BankIdWrapper({
  onAuthenticated,
  bankIdDescription,
  bankIdTitle,
  successComponent: SuccessComponent,
  bankIdOptions = {},
  debug = false,
}: BankIdWrapperProps) {
  const bankId = useBankId(bankIdOptions);

  const {
    qrCodeImage,
    identification,
    hasError,
    qrDataString,
    hasTimedOut,
    startToken,
    autoStartToken,
    hintCode,
    retriggerAuth,
    reset,
  } = bankId;

  const identificationStatus = useMemo(
    () => (debug ? "completed" : identification?.status),
    [identification?.status, debug]
  );

  const bankIdReducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_BANKID_DATA":
        return { ...state, ...action.payload };
      case "SET_AUTHENTICATION_METHOD":
        return { ...state, authenticationMethod: action.payload };
      default:
        return state;
    }
  };

  const initialState = {
    qrCodeImage,
    qrDataString,
    identification,
    autoStartToken,
    hasTimedOut,
    startToken,
    hasError,
    hintCode,
    retriggerAuth,
    reset,
    authenticationMethod: "",
  };

  const identificationData =
    debug && identificationDebug ? identificationDebug : identification;

  const [state, dispatch] = useReducer(bankIdReducer, initialState);
  const authenticationMethod = state?.authenticationMethod;

  useEffect(() => {
    dispatch({
      type: "SET_BANKID_DATA",
      payload: {
        qrCodeImage,
        qrDataString,
        identification,
        hasTimedOut,
        autoStartToken,
        startToken,
        hintCode,
        hasError,
        authenticationMethod,
        retriggerAuth,
        reset,
      },
    });
  }, [
    qrCodeImage,
    qrDataString,
    identification,
    hasTimedOut,
    startToken,
    hasError,
    hintCode,
    autoStartToken,
  ]);

  const openAutoStart = () => {
    dispatch({ type: "SET_AUTHENTICATION_METHOD", payload: "auto" });
    retriggerAuth();
  };

  const openQRCode = () => {
    dispatch({ type: "SET_AUTHENTICATION_METHOD", payload: "qr" });
    retriggerAuth();
  };

  useEffect(() => {
    if (identificationStatus !== "complete" || !onAuthenticated) return;

    onAuthenticated({
      identification,
      bankId,
    });
  }, [identificationStatus]);

  return (
    <BankIdContext.Provider value={{ state, dispatch }}>
      <div className="w-full">
        {identificationData?.status === "complete" && SuccessComponent && (
          <SuccessComponent
            identification={identificationData}
            bankId={bankId}
          />
        )}

        {identificationData?.status !== "complete" &&
        ["auto", "qr"].includes(authenticationMethod) ? (
          <AuthenticationView />
        ) : null}

        {identificationData?.status !== "complete" &&
        authenticationMethod === "" ? (
          <div className="flex flex-col space-y-4 md:space-y-6">
            <Button
              onClick={openQRCode}
              className="p-4 bg-blue text-sm md:text-base font-semibild text-white justify-center"
              borderColor="transparent"
              icon="bankid"
              iconDirection="left"
            >
              Mobilt BankID på annan enhet
            </Button>
            <Button
              className="p-4 bg-transparent font-semibild text-blue justify-center text-sm md:text-base"
              borderColor="blue"
              onClick={openAutoStart}
              icon="bankid"
              iconDirection="left"
            >
              BankID på denna enheten
            </Button>
          </div>
        ) : null}

        {(bankIdTitle || bankIdDescription) && (
          <div className="flex flex-col space-y-2 md:space-y-4 mt-8 text-base md:text-base mb-4">
            {bankIdTitle && <strong>{bankIdTitle}</strong>}
            {bankIdDescription && (
              <p className="text-base md:text-base">{bankIdDescription}</p>
            )}
          </div>
        )}
      </div>
    </BankIdContext.Provider>
  );
}
