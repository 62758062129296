import { useEffect, useState } from "react";
import toast from "react-hot-toast";
import { userBankIdPreRegistration } from "@/actions/user";

const useBankIdVerify = (
    pin: string,
    secret: string,
    {
        reset,
        dispatch,
        LoginStateDispatch,
        BankIdDispatch,
    }: {
        reset: () => void;
        dispatch: (action: any) => void;
        LoginStateDispatch: (action: any) => void;
        BankIdDispatch: (action: any) => void;
    }
) => {
    const [accountData, setAccountData] = useState<any | null>(null);
    const [error, setError] = useState<Error | null>(null);
    const [isLoading, setIsLoading] = useState(false);

    const fetchBankIdVerification = async () => {
        if (!pin) {
            dispatch({ type: "RESET", payload: true });
            return;
        }

        setIsLoading(true);

        try {
            const response = await userBankIdPreRegistration(pin, secret);
            setAccountData(response);
            setError(null);
        } catch (err: any) {
            setError(err);

            // Handle error scenario
            reset();
            toast.error(
                "Tiden för BankID verifiering har gått ut, vänligen försök igen.",
                {
                    id: "form-error-verify",
                }
            );
            LoginStateDispatch({
                type: "SET_BANK_ID_IDENTIFICATION",
                payload: false,
            });

            BankIdDispatch({ type: "SET_BANKID_DATA", payload: false });
            BankIdDispatch({ type: "SET_VIEW", payload: "" });
            dispatch({ type: "RESET", payload: true });
        } finally {
            setIsLoading(false);
        }
    };

    useEffect(() => {
        const interval = setInterval(() => {
            fetchBankIdVerification();
        }, 10 * 1000); // 10 seconds interval

        return () => clearInterval(interval);
    }, [pin, secret]); // Re-run only if pin or secret changes

    return { accountData, error, isLoading };
};

export default useBankIdVerify;