"use client";

import Input from "@/components/Elements/Input";
import {
  Accordion,
  AccordionBody,
  AccordionHeader,
} from "@material-tailwind/react";
import classNames from "classnames";
import React, { useEffect, useState } from "react";

const ArrowIcon = (props: any) => {
  const { open, setOpen } = props.openProps;

  return (
    <span onClick={() => setOpen(!open)}>
      <svg
        width="22"
        height="15"
        viewBox="0 0 20 13"
        className={`${open ? "rotate-180" : ""} h-3 w-3 transition-transform`}
        fill="none"
        xmlns="http://www.w3.org/2000/svg"
      >
        <path
          d="M0 2.025L1.775 0.25L10 8.475L18.225 0.25L20 2.025L10 12.025L0 2.025Z"
          fill="#2D2D32"
        />
      </svg>
    </span>
  );
};

const Row: React.FC<any> = ({ fields, title, errors, register }) => {
  const accordionClass = classNames("w-full block sm:px-0", {
    "-mb-2": false,
  });

  const [open, setOpen] = useState(true);

  useEffect(() => {
    const handleResize = () => {
      if (window.innerWidth < 700) {
        setOpen(true);
      }
    };

    window.addEventListener("resize", handleResize);

    return () => {
      window.removeEventListener("resize", handleResize);
    };
  }, []);

  return (
    <>
      <Accordion
        open={open}
        icon={<ArrowIcon id={1} openProps={{ open, setOpen }} />}
        className={accordionClass}
      >
        <AccordionHeader className="flex sm:hidden items-center justify-between py-4 border-b bg-transparent border-none rounded-lg w-full">
          <span className="order-0 text-gray-dark text-xl font-normal flex flex-row space-x-4 items-center">
            {title}
            <span onClick={() => setOpen(!open)}></span>
          </span>
        </AccordionHeader>
        <AccordionBody className="flex flex-col space-y-4">
          {fields &&
            fields.map((field: any) => {
              const hasError =
                errors[field.key] !== undefined && field?.required;

              const inputWrapperClass = classNames(
                "flex flex-col bg-white rounded-md",
                {
                  "border-l-4 border-blue border-solid": field?.canEdit,
                },
                {
                  "opacity-50 pointer-events-none": !field?.canEdit,
                },
                {
                  "!border-danger": hasError,
                }
              );

              return (
                <div key={field.key} className={inputWrapperClass}>
                  <label
                    htmlFor={field.key}
                    className="uppercase font-light tracking-wide text-black text-12 mt-2 px-4 flex flex-row items-center"
                  >
                    <span>{field.label}</span>
                    {field.required && (
                      <span className="text-red inline-block ml-2 text-lg mt-2">
                        *
                      </span>
                    )}
                  </label>
                  {field.type === "select" ? (
                    <select
                      id={field.key}
                      disabled={!field?.canEdit}
                      className="text-black md:text-xl m-0 !p-3 !px-4 mr-4 !border-none"
                      {...register(field.key, {
                        required: field.required,
                        ...field.validateProps,
                      })}
                      defaultValue={field?.value}
                    >
                      {field?.options.map((option: any) => (
                        <option key={option.value} value={option.value}>
                          {option.label}
                        </option>
                      ))}
                    </select>
                  ) : (
                    <Input
                      id={field.key}
                      type={field.type}
                      placeholder={field?.placeholder || field.label}
                      disabled={!field?.canEdit}
                      inputClassName={`text-black md:text-xl m-0`}
                      {...register(field.key, {
                        required: field.required,
                        ...field.validateProps,
                      })}
                    />
                  )}
                </div>
              );
            })}
        </AccordionBody>
      </Accordion>
    </>
  );
};

export default Row;
