"use client";

import React, { useContext, useEffect, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import useSWR from "swr";
import Checkbox from "@/components/Elements/Checkbox";
import Button from "@/components/Elements/Button";
import {
  bankIdGetCreditSafeDetails,
  getLatestTermsForLoggedOutUser,
} from "@/actions/user";
import toast from "react-hot-toast";
import Row from "../../Row";
import { validatePassword, validateUsername } from "@/lib/user";
import ArrowButton from "@/components/Elements/ArrowButton";
import { AccountContext } from "../context/AccountContext";
import BeatLoader from "react-spinners/BeatLoader";
import ReactHtmlParser from "html-react-parser";
import { getErrorCodeMessage } from "@/lib/reporting";
import useValidateBankIdToken from "../methods/useValidateBankIdToken";
import ModalElement from "@/components/Modal/Modal";

const CreateAccount = () => {
  const {
    state: { identification, bankId, account },
    dispatch,
  } = useContext(AccountContext);
  const [isAgreementModalOpen, setIsAgreementModalOpen] = useState(false);
  const [isSubmitting, setIsSubmitting] = useState(false);
  const formRef = useRef<HTMLFormElement>(null);
  const verifyPin = identification?.completionData?.user?.personalNumber || "";
  const registerPin = account?.id;
  const secret = identification?.secret;
  const hasData = verifyPin && registerPin && secret;

  const {
    data: responseData,
    error,
    isLoading,
  } = useSWR<any, Error>(
    hasData && [
      "api/userbankIdGetCreditSafeDetails",
      verifyPin,
      registerPin,
      secret,
    ],
    async () => {
      const userInfo = await bankIdGetCreditSafeDetails(
        verifyPin,
        registerPin,
        secret,
        account?.type
      );

      const agreementData = await getLatestTermsForLoggedOutUser();
      const agreementSE = Object.values(agreementData).find(
        (agreement: any) => agreement?.textLangId === 1
      );

      return {
        aUserData: userInfo,
        agreementData: agreementSE,
      };
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  // Verify bankId and regenerate token if neccesary
  useValidateBankIdToken(registerPin, secret);

  const {
    control,
    trigger,
    register,
    handleSubmit,
    setValue,
    getValues,
    watch,
    reset,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      passwords: [{ password: "", password_repeat: "" }],
    },
  });

  let bankIdInfo = {} as any;

  const agreementData = responseData?.agreementData;
  const bankIdUserInfo = responseData?.aUserData?.data?.aUserData;
  const userType = bankIdUserInfo?.userType;

  useEffect(() => {
    if (bankIdUserInfo) {
      const flatFieldValues = Object.values(inputFields).flatMap(
        (group: any) => group
      );

      flatFieldValues.forEach((field: any) => {
        const valueWrapper = field?.valueWrapper;
        const userInfoValue = bankIdUserInfo[field.key];

        if (userInfoValue && valueWrapper) {
          bankIdInfo[field.key] = valueWrapper(userInfoValue);
        } else {
          bankIdInfo[field.key] = userInfoValue;
        }
      });

      reset(bankIdInfo);
    }
  }, [bankIdUserInfo, reset]);

  const inputFields = {
    userRow: [
      {
        key: "username",
        label: "Användarnamn",
        type: "text",
        placeholder: "Användarnamn (Visas i budhistoriken)",
        validateProps: {
          validate: (value: any) =>
            validateUsername(value) ||
            "Användarnamnet får endast innehålla bokstäver (ej innehålla tovek), siffror och specialtecken (_-) och vara mellan 6-24 tecken långt",
        },
        canEdit: true,
        required: true,
      },
      {
        key: "password",
        label: "Lösenord",
        type: "password",
        required: true,
        validateProps: {
          validate: (value: any) =>
            validatePassword(value) ||
            "Lösenordet måste vara minst 8 och max 120 tecken långt och innehålla två av följande: stor bokstav, liten bokstav, siffra, specialtecken",
        },
        canEdit: true,
      },
      {
        key: "password2",
        label: "Lösenord (igen)",
        type: "password",
        required: true,
        validateProps: {
          required: "Du måste bekräfta ditt lösenord.",
          validate: (value: any) => {
            const match = watch("password") === value;
            return match || "Lösenorden matchar inte.";
          },
        },
        canEdit: true,
      },
    ],
    contactInfo: [
      {
        key: "userEmail",
        label: "E-post",
        type: "email",
        required: true,
        validateProps: {
          pattern: {
            value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            message: "Ogiltlig e-postaddress",
          },
        },
        canEdit: true,
      },
      {
        key: "infoCellPhone",
        label: "Mobil",
        type: "text",
        required: false,
        canEdit: true,
        validateProps: {
          validate: (value: any) => {
            const mobileNumberRegex = /^[+]?[\d\s()-]{6,15}$/;

            if (value && !mobileNumberRegex.test(value)) {
              return "Ogiltigt mobilnummer";
            }
          },
        },
      },
    ],
    basicInfo: [
      {
        key: "userType",
        label: "Kundtyp",
        type: "text",
        validateProps: {},
        value: "Privatperson",
        canEdit: false,
        valueWrapper: (value: any) => {
          if (value == "privatePerson") {
            return "Privatperson";
          } else if (value == "company") {
            return "Företag";
          }

          return "Ej tilldelad";
        },
      },
    ],
    addressInfo: [
      {
        key: "infoAddress",
        label: "Gatuadress",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
      {
        key: "infoZipCode",
        label: "Postnummer",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
      {
        key: "infoCity",
        label: "Ort",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
      {
        key: "infoCountryCode",
        label: "Land",
        type: "select",
        options: [
          { label: "Sverige", value: "SE" },
          { label: "Norge", value: "NO" },
          { label: "Danmark", value: "DK" },
          { label: "Finland", value: "FI" },
        ],
        required: true,
        validateProps: {},
        canEdit: false,
      },
    ],
  } as any;

  if (userType == "company") {
    inputFields.basicInfo = [
      ...inputFields.basicInfo,
      {
        key: "infoName",
        label: "Företgets namn",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
    ];
  } else {
    inputFields.basicInfo = [
      ...inputFields.basicInfo,
      {
        key: "infoFirstname",
        label: "Förnamn",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
      {
        key: "infoSurname",
        label: "Efternamn",
        type: "text",
        required: true,
        validateProps: {},
        canEdit: false,
      },
    ];
  }

  const onSubmit: SubmitHandler<FieldValues> = async (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    setIsSubmitting(true);

    dispatch({
      type: "UPDATE_STATE",
      payload: {
        registrationFields: data,
        secret,
        view: "register-bankid",
      },
    });
  };

  const validateForm = async (e: any) => {
    e.preventDefault();
    const isValid = await trigger();

    // Get errors
    const allErrors = Object.entries(errors).map(([key, value]) => {
      return {
        key: key,
        type: value?.type,
        message: value?.message,
      };
    }) as any;

    const hasRequiredFields = allErrors.some(
      (error: any) => error.type === "required"
    );

    if (!bankIdUserInfo) {
      toast.error(
        "Vi kunde inte hämta några uppgifter, vänligen försök igen senare. (7)"
      );
      return;
    }

    if (isValid) {
      handleSubmit(onSubmit)(e);
    } else if (hasRequiredFields) {
      toast.error("Vänligen fyll i alla obligatoriska fält.", {
        id: "form-error-toast",
      });
    } else {
      toast.error(allErrors[0].message || "Vänligen fyll i alla fält korrekt.");
    }
  };

  const hasAgreed = watch("register.hasAgreed", false);

  const handleToggle = (field: any) => {
    setValue(field, !watch(field));
  };

  useEffect(() => {
    if (error) {
      reset();

      toast.error(
        getErrorCodeMessage(
          error.message,
          null,
          "Vi kunde inte slutföra registreringen, vänligen försök igen senare"
        ),
        {
          id: "form-error-verify",
        }
      );

      dispatch({ type: "SET_VIEW", payload: "choose" });
    }
  }, [error]);

  if (isLoading) {
    return <BeatLoader color="#183e4f" />;
  }

  return (
    <>
      <ModalElement
        isOpen={isAgreementModalOpen}
        size="xl"
        modalBg="bg-white"
        modalTitle={agreementData?.titleTextContent || "Registreringsavtal"}
        modalBody={
          <>
            <div className="max-h-[500px] overflow-x-hidden overflow-y-scroll relative pb-8 min-h-[500px] bg-gray-200 p-4">
              {agreementData?.contentTextContent &&
                ReactHtmlParser(agreementData?.contentTextContent)}
              <div className="shadow-scroll-gray sticky bottom-0"></div>
            </div>
            <div className="mt-8 flex justify-center items-center space-x-6">
              <Button
                className="p-4 bg-blue font-semibild text-white justify-center text-md"
                borderColor="blue"
                onClick={() => {
                  setValue("register.hasAgreed", true);
                  setIsAgreementModalOpen(false);
                }}
              >
                Acceptera villkoren
              </Button>
              <Button
                className="p-4 bg-transparent font-semibild text-blue justify-center text-md"
                borderColor="blue"
                onClick={() => {
                  setIsAgreementModalOpen(false);
                }}
              >
                Avbryt
              </Button>
            </div>
          </>
        }
        onClose={() => setIsAgreementModalOpen(false)}
      />
      <form
        ref={formRef}
        onSubmit={validateForm}
        className="flex flex-col w-full"
      >
        <span className="my-6 mx-auto">
          <ArrowButton
            text="Välj ett annat konto"
            direction="left"
            onClick={() => dispatch({ type: "SET_VIEW", payload: "choose" })}
          />
        </span>
        <div className="p-4 px-6 bg-aliceblue text-toast-blue font-light rounded-lg flex flex-col space-y-2 !mb-10">
          <h3 className="text-xl text-modal-blue mt-4 hidden sm:block">
            Inloggningsuppgifter
          </h3>
          <div className="flex flex-col">
            <div className="flex flex-col flex-grow">
              <div className="flex flex-col sm:space-y-4">
                <Row
                  fields={inputFields.userRow}
                  title="Grunduppgifter"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue mt-4 hidden sm:block">
                Kontaktuppgifter
              </h3>
              <div className="flex flex-col sm:space-y-4">
                <Row
                  fields={inputFields.contactInfo}
                  title="Adress"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue mt-4 hidden sm:block">
                Grunduppgifter
              </h3>
              <div className="flex flex-col sm:space-y-4">
                <Row
                  fields={inputFields.basicInfo}
                  title="Kontaktuppgifter"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>

            <div className="flex flex-col flex-grow">
              <h3 className="text-xl text-modal-blue mt-4 hidden sm:block">
                Adress
              </h3>
              <div className="flex flex-col sm:space-y-4">
                <Row
                  fields={inputFields.addressInfo}
                  title="Kontaktuppgifter"
                  register={register}
                  errors={errors}
                />
              </div>
            </div>
          </div>
          <div className="flex flex-col">
            <span className="my-4">* obligatoriskt fält</span>

            <div className="flex flex-row items-center space-x-3 text-16">
              <Checkbox
                {...register("register.hasAgreed", {
                  required: "Du måste godkänna registreringsavtalet.",
                })}
                ripple={false}
                required={true}
                onChange={() => handleToggle("register.hasAgreed")}
                checked={hasAgreed}
              />
              <label htmlFor="register.hasAgreed" className="text-sm">
                Jag har läst igenom{" "}
                {agreementData?.contentTextContent ? (
                  <span
                    className="font-bold underline"
                    role="button"
                    onClick={() => setIsAgreementModalOpen(true)}
                  >
                    registreringsavtalet
                  </span>
                ) : (
                  "registreringsavtalet"
                )}{" "}
                och godkänner det
              </label>
            </div>

            <Button
              className="mt-8 w-full py-5"
              bgColor="blue"
              type="submit"
              textCenter
              icon="bankid"
              iconDirection="left"
              onClick={() => trigger()}
              disabled={isSubmitting}
            >
              Signera med BankId
            </Button>
          </div>
        </div>{" "}
      </form>
    </>
  );
};

export default CreateAccount;
