import classNames from "node_modules/classnames";
import React from "react";

interface HeadingProps {
  size?: "sm" | "md" | "lg";
  className?: string;
  children?: React.ReactNode;
}

const Heading: React.FC<HeadingProps> = ({
  size = "lg",
  className = "",
  children = null,
}) => {
  const headingClass = classNames(
    "font-semibold inline-block text-modal-blue leading-10",
    {
      "text-2xl": size === "md",
      "text-32": size === "lg",
      [className]: className,
    }
  );
  return <h1 className={headingClass}>{children}</h1>;
};

export default Heading;
