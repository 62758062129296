import useBankId from "@/hooks/useBankId";
import React, { useContext, useEffect, useMemo, useReducer } from "react";
import toast from "react-hot-toast";
import Alert from "@/components/Elements/Alert";
import Button from "@/components/Elements/Button";
import { bankIdRegistration } from "@/actions/user";
import { signIn } from "next-auth/react";
import { AccountContext } from "../context/AccountContext";
import { BankIdRegisterContext } from "../context/BankIdRegisterContext";
import BeatLoader from "react-spinners/BeatLoader";
import RegisterAuthenticationView from "../methods/RegisterAuthenticationView";
import { AuthenticationContext } from "../../../Wrapper";
import ArrowButton from "@/components/Elements/ArrowButton";
import { getErrorCodeMessage, getStatusCodeMessage } from "@/lib/reporting";

const MESSAGE_NO_REGISTER =
  "Vi kunde tyvärr inte verifiera dina uppgifter, om du är säker på att du har skrivit in rätt uppgifter, försök igen senare. Kontakta oss om problemet kvarstår.";

export default function RegisterUserBankId() {
  const [isLoggingIn, setIsLoggingIn] = React.useState(false);
  const [registerStatus, setRegisterStatus] = React.useState<any>(false);
  const [showTryMore, setShowTryMore] = React.useState(true);

  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);

  let {
    state: { secret, view: stateView, registrationFields, account },
    dispatch: AccountContextDispatch,
  } = useContext(AccountContext) as any;

  const signBankId = useBankId({
    userVisibleData:
      "Jag registrerar mig på tovek.se och godkänner användarvillkoren.",
    bankIdMethod: "sign",
  });

  const { qrCodeImage, identification, hasTimedOut, retriggerAuth, reset } =
    signBankId;

  const bankIdReducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_BANKID_DATA":
        return { ...state, ...action.payload };
      case "SET_VIEW":
        return { ...state, view: action.payload };
      default:
        return state;
    }
  };

  const initialState = {
    qrCodeImage,
    identification,
    hasTimedOut,
    retriggerAuth,
    authenticationMethod: "qr",
    reset,
    view: "sign",
  };

  const [state, dispatch] = useReducer(bankIdReducer, initialState);

  let verifyPin = identification?.completionData?.user?.personalNumber || "";

  const view = state?.view;

  useEffect(() => {
    if (view !== "") return;
    reset();
    AccountContextDispatch({ type: "RESET" });
  }, [view]);

  useEffect(() => {
    dispatch({ type: "SET_BANKID_DATA", payload: signBankId });
  }, [identification, hasTimedOut, qrCodeImage]);

  useEffect(() => {
    dispatch({ type: "SET_VIEW", payload: view });
  }, [view]);

  const bankIdVerified = identification?.status === "complete";
  const signSecret = identification?.secret;
  const registerPin = account?.id;

  const tryAgainBankd = () => {
    AccountContextDispatch({ type: "SET_VIEW", payload: "register" });
  };

  useEffect(() => {
    if (!bankIdVerified || !signSecret) {
      return;
    }

    if (!verifyPin || !secret) {
      setRegisterStatus({
        ...registerStatus,
        registerFields: getStatusCodeMessage("", MESSAGE_NO_REGISTER),
      });
      return;
    }

    bankIdRegistration(
      verifyPin,
      registerPin,
      signSecret,
      identification,
      registrationFields
    )
      .then((response) => {
        const token = response?.token;
        const userId = response?.userId;

        if (!response || response?.status == "error") {
          setRegisterStatus({
            ...registerStatus,
            registerFields: getErrorCodeMessage(
              response?.errorCode,
              null,
              MESSAGE_NO_REGISTER
            ),
          });
          return;
        }

        setIsLoggingIn(true);

        toast.loading("Ditt konto är registrerat, loggar in ...", {
          id: "login-toast",
          duration: 10000,
        });

        signIn("tovek-session", {
          userId: userId,
          token: token,
          secret: secret,
          redirect: false,
        })
          .then((res: any) => {
            if (!res || res?.error) {
              throw new Error("Error signing in");
            }

            toast.success("Du är nu inloggad!", {
              id: "login-toast",
              duration: 3000,
            });
            //router.refresh();
            window.location.reload();
          })
          .catch((error: any) => {
            setRegisterStatus({
              ...registerStatus,
              title: "Konto skapat!",
              accountCreated: true,
              registerFields:
                "Vi kunde tyvärr inte automatiskt logga in på ditt konto, pröva att gå till mitt-konto och pröva att logga in manuellt.",
            });
            setShowTryMore(false);

            toast.remove("login-toast");
            setIsLoggingIn(false);
          });
      })
      .catch((error) => {
        console.error("Error in bankIdPostRegistration:", error);
        setRegisterStatus({
          ...registerStatus,
          registerFields: getStatusCodeMessage("", MESSAGE_NO_REGISTER),
        });
      });
  }, [bankIdVerified, signSecret]);

  useEffect(() => {
    window.scrollTo(0, 0);
  }, [registerStatus]);

  if (isLoggingIn) {
    return (
      <div className="flex flex-col justify-center items-center w-full">
        <BeatLoader color="#183e4f" />
      </div>
    );
  }

  return (
    <BankIdRegisterContext.Provider value={{ state, dispatch }}>
      <div className="w-full">
        {registerStatus ? (
          <div className="flex flex-col w-full">
            <Alert className="bg-palegoldenrod w-fit text-neutral-dark-gray font-normal p-6">
              <div className="flex flex-col">
                <h3 className="mb-2 text-2xl">
                  {registerStatus.title ||
                    "Vi kunde inte registrera ditt konto"}
                </h3>
                <div>{registerStatus.registerFields}</div>

                {registerStatus?.accountCreated && (
                  <div className="text-18 mt-5">
                    <ArrowButton
                      text="Logga in"
                      direction="right"
                      onClick={() =>
                        LoginStateDispatch({
                          type: "SET_SHOW",
                          payload: "login",
                        })
                      }
                    />
                  </div>
                )}
              </div>
            </Alert>

            {showTryMore && (
              <div className="flex items-center justify-center">
                <div className="inline-flex flex-row space-x-4 mt-6">
                  <Button
                    bgColor="white"
                    className="text-black !border !border-black mx-auto text-md mt-4"
                    onClick={tryAgainBankd}
                  >
                    Avbryt
                  </Button>

                  <Button
                    bgColor="blue"
                    className="text-white !border !border-blue mx-auto text-md mt-4"
                    onClick={tryAgainBankd}
                  >
                    Försök igen
                  </Button>
                </div>
              </div>
            )}
          </div>
        ) : (
          <RegisterAuthenticationView />
        )}
      </div>
    </BankIdRegisterContext.Provider>
  );
}
