import Button from "@/components/Elements/Button";
import { useContext } from "react";
import { BankIdContext } from "@/components/BankId/BankIdContext";

interface MethodFooterProps {
  reTriggerView: string;
  cancelAction?: () => void;
  tryAgainAction?: () => void;
  hasTimedOut?: boolean;
  hasError?: boolean;
}

const MethodFooter: React.FC<MethodFooterProps> = ({
  reTriggerView,
  cancelAction,
  tryAgainAction,
  hasTimedOut: hasTimedOutProp,
  hasError: hasErrorProp,
}) => {
  const { state, dispatch } = useContext(BankIdContext);

  const { hasTimedOut, hasError, reset, retriggerAuth } = state;

  const checkIfTimedOut =
    hasTimedOutProp !== undefined ? hasTimedOutProp : hasTimedOut;
  const checkIfError = hasErrorProp !== undefined ? hasErrorProp : hasError;

  // Default actions for cancelAction and tryAgainAction if not provided
  const handleCancelAction =
    cancelAction ||
    (() => {
      dispatch({ type: "SET_AUTHENTICATION_METHOD", payload: "" });
      reset();
    });

  const handleTryAgainAction =
    tryAgainAction ||
    (() => {
      dispatch({ type: "SET_AUTHENTICATION_METHOD", payload: reTriggerView });
      retriggerAuth();
    });

  return (
    <div className="flex items-center justify-center">
      <div className="inline-flex flex-row space-x-4 mt-6">
        <Button
          bgColor="white"
          className="text-black !border !border-black mx-auto text-md mt-4"
          onClick={handleCancelAction}
        >
          Avbryt
        </Button>
        {(checkIfTimedOut || checkIfError) && (
          <Button
            bgColor="blue"
            className="text-white !border !border-blue mx-auto text-md mt-4"
            onClick={handleTryAgainAction}
          >
            Försök igen
          </Button>
        )}
      </div>
    </div>
  );
};

export default MethodFooter;
