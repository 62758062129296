"use client";

import { useEffect, useState, useCallback, useRef } from "react";
import QRCode from "qrcode";

const COLLECT_INTERVAL = 1000; // Refresh every 1 second
const SIGNUP_TIMEOUT = 28000; // Timeout after 28 seconds

interface BankIdOptions {
    userVisibleData?: string;
    bankIdMethod?: "sign" | "auth";
    skipSetBankIdServerSecret?: boolean;
}

const useBankId = (options?: BankIdOptions) => {
    const [triggerCounter, setTriggerCounter] = useState(0);
    const [auth, setAuthData] = useState<any>(null);
    const [hasTimedOut, setHasTimedOut] = useState(false);
    const [hasError, setHasError] = useState(false);
    const [completed, setCompleted] = useState(false);
    const [qrCodeImage, setQRCodeImage] = useState<string>("");
    const [qrDataString, setQRDataString] = useState<string>("");
    const [identification, setIdentification] = useState<any>(null);
    const [autoSignIn, setAutoSignIn] = useState(false);
    const [hintCode, setHintCode] = useState<string>("");

    // Track ongoing fetch requests
    const isFetchingCollect = useRef(false);
    const collectIntervalRef = useRef<NodeJS.Timeout | null>(null);

    const reset = useCallback(() => {
        setQRCodeImage("");
        setAuthData(null);
        setIdentification(null);
        setCompleted(false);
        setHasTimedOut(false);
        setHasError(false);
        setAutoSignIn(false);

        // Clear interval when resetting
        if (collectIntervalRef.current) {
            clearInterval(collectIntervalRef.current);
            collectIntervalRef.current = null;
        }

        if (isFetchingCollect.current) {
            isFetchingCollect.current = false;
        }
    }, []);

    const retriggerAuth = useCallback(() => {
        reset();
        setTriggerCounter((prevCount) => prevCount + 1);
    }, [reset]);

    // Init the auth/sign process
    useEffect(() => {
        if (auth || triggerCounter === 0) return;

        setHasTimedOut(false);

        const signInTimeout = setTimeout(() => {
            setHasTimedOut(true);
        }, SIGNUP_TIMEOUT);

        const fetchData = async () => {
            let params: { method: any; userVisibleData?: any } = {
                method: options?.bankIdMethod || "auth",
            };

            if (options?.userVisibleData) {
                params.userVisibleData = options.userVisibleData;
            }

            const queryString = new URLSearchParams(params).toString();

            const MAX_RETRIES = 5;
            const RETRY_DELAY = 1000;

            const retryFetch = async (attempt: number = 1): Promise<any> => {
                try {
                    const response = await fetch(`/api/bankId?${queryString}`, {
                        method: "GET",
                        headers: {
                            "Content-Type": "application/json",
                            "x-api-key": process.env.NEXT_PUBLIC_BANKID_VERIFICATION_API_KEY || "",
                            Accept: "application/json",
                        },
                    });

                    const data = await response.json();

                    if (!data) {
                        throw new Error("No data returned from the API");
                    }

                    setAuthData(data);
                    return data;
                } catch (error: any) {
                    console.error(`Attempt ${attempt} failed:`, error);
                    if (attempt < MAX_RETRIES) {
                        await new Promise((resolve) => setTimeout(resolve, RETRY_DELAY));
                        return retryFetch(attempt + 1);
                    } else {
                        setHasError(true);
                        throw error;
                    }
                }
            };

            try {
                await retryFetch();
            } catch (error) {
                console.error("Failed to set auth data after retries:", error);
            }
        };

        fetchData();

        return () => clearTimeout(signInTimeout);
    }, [triggerCounter]);

    // Init the collection process
    useEffect(() => {
        if (triggerCounter === 0 || !auth?.orderRef) return;

        const collectToken = async () => {
            if (isFetchingCollect.current) return;
            isFetchingCollect.current = true;

            const params = {
                params: JSON.stringify({
                    orderRef: auth.orderRef,
                    skipSetBankIdServerSecret: options?.skipSetBankIdServerSecret,
                    qrStartToken: auth.qrStartToken,
                }),
                method: "collect",
            };

            const queryString = new URLSearchParams(params).toString();

            if (hasTimedOut || hasError) {
                return;
            }

            try {
                const response = await fetch(`/api/bankId?${queryString}`, {
                    method: "GET",
                    headers: {
                        "Content-Type": "application/json",
                        "x-api-key": process.env.NEXT_PUBLIC_BANKID_VERIFICATION_API_KEY || "",
                        Accept: "application/json",
                    },
                });

                const { identificationData, qrCodeData } = await response.json();

                setQRDataString(qrCodeData);
                setHintCode(identificationData?.hintCode);

                if (identificationData?.status === "complete") {
                    setIdentification(identificationData);
                    setCompleted(true);
                } else if (identificationData?.status === "failed") {
                    setHasError(true);
                }
            } catch (error: any) {
                setHasError(true);
            } finally {
                isFetchingCollect.current = false; // Allow new requests after completion
            }
        };

        collectIntervalRef.current = setInterval(collectToken, COLLECT_INTERVAL);

        return () => {
            if (collectIntervalRef.current) {
                clearInterval(collectIntervalRef.current);
                collectIntervalRef.current = null;
            }
        };
    }, [auth, hasTimedOut, completed, hasError, triggerCounter]);

    // Generate QR code image
    useEffect(() => {
        if (!qrDataString || hasTimedOut || hasError || autoSignIn) return;

        QRCode.toString(qrDataString, { type: 'svg' })
            .then((svgString) => {
                setQRCodeImage(`data:image/svg+xml;base64,${btoa(svgString)}`);
            })
            .catch((error: any) => {
                setQRCodeImage("");
            });
    }, [qrDataString]);

    return {
        qrCodeImage,
        hasTimedOut,
        qrDataString,
        identification,
        hasError,
        hintCode,
        retriggerAuth,
        triggerAutoSignIn: () => setAutoSignIn(true),
        autoStartToken: auth?.autoStartToken,
        startToken: auth?.qrStartToken,
        reset,
    };
};

export default useBankId;