"use client";

import {
  checkPinNumber,
  pinLogin,
  userBankIdPreRegistration,
  userValidateBankIdToken,
} from "@/actions/user";
import React, { useCallback, useContext, useEffect, useState } from "react";
import Skeleton from "react-loading-skeleton";
import useSWR, { mutate } from "swr";
import { AuthenticationContext } from "../../../Wrapper";
import toast from "react-hot-toast";
import { RegisterContext } from "../..";
import { BankIdContext } from "@/components/BankId/BankIdContext";
import useUserStore from "@/stores/useUserStore";
import { useRouter } from "next/navigation";
import useModalStore from "@/hooks/useModalStore";
import classNames from "classnames";
import { signIn } from "next-auth/react";
import Button from "@/components/Elements/Button";
import { AccountContext } from "../context/AccountContext";
import BeatLoader from "react-spinners/BeatLoader";
import Alert from "@/components/Elements/Alert";
import Link from "next/link";
import { getErrorCodeMessage } from "@/lib/reporting";
import useBankIdVerify from "../methods/useBankIdVerify";
import useValidateBankIdToken from "../methods/useValidateBankIdToken";

const ChooseAccount = () => {
  const {
    state: { identification, bankId },
    dispatch,
  } = useContext(AccountContext);

  const { dispatch: RegisterDispatch } = useContext(RegisterContext);
  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);
  const { dispatch: BankIdDispatch } = useContext(BankIdContext);

  const secret = identification?.secret;
  const pin = identification?.completionData?.user?.personalNumber || "";
  const { reset } = bankId;

  const [isActiveSignIn, setIsActiveSignIn] = useState(false);
  const router = useRouter();
  const { loginModal } = useModalStore();

  const blockClass = classNames("flex flex-col space-y-5", {
    "opacity-50 pointer-events-none	": isActiveSignIn,
  });

  const loginUser = useCallback(
    async (userId: number) => {
      if (!userId) {
        toast.error(
          "Kunde inte logga in, försök igen eller pröva att ladda om sidan.",
          {
            id: "form-error-verify",
          }
        );
        return;
      }

      setIsActiveSignIn(true);
      const loadingToast = toast.loading("Loggar in ..");

      const errorMessage =
        "Kunde inte logga in, försök igen eller pröva att ladda om sidan.";

      try {
        const response = await pinLogin(pin, userId, secret);

        if (response?.token) {
          signIn("tovek-session", {
            userId: userId,
            secret: secret,
            token: response?.token,
            redirect: false,
          })
            .then((res: any) => {
              if (!res || res?.error) {
                toast.error(
                  getErrorCodeMessage(res?.error, null, errorMessage),
                  {
                    id: "form-error-verify",
                    duration: 10000,
                  }
                );
                throw new Error("Error signing in");
              }

              toast.success("Du är nu inloggad!", {
                id: "login-toast",
                duration: 3000,
              });
              toast.dismiss("form-error-verify");
              loginModal.onClose();
              window.location.reload();
              //router.refresh();
            })
            .catch((data: any) => {
              setIsActiveSignIn(false);
            });
        } else {
          toast.error(
            getErrorCodeMessage(
              response?.error?.code,
              null,
              "Din session har gått ut, vänligen logga in igen."
            ),
            {
              id: "form-error-verify",
              duration: 10000,
            }
          );
          router.refresh();
        }
      } catch (error: any) {
        console.error("Error login in:", error.message);
        toast.error(errorMessage, {
          id: "form-error-verify",
        });
        setIsActiveSignIn(false);
      } finally {
        toast.remove(loadingToast);
      }
    },
    [secret, router, loginModal]
  );

  // Keep alive
  useValidateBankIdToken(pin, secret);

  const {
    data: accountData,
    error,
    isLoading,
  } = useSWR<any, Error>(
    ["api/userBankIdPreRegistration", pin],
    async () => {
      const response = await userBankIdPreRegistration(pin, secret);
      return response;
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  // Verify bankId and regenerate token if neccesary
  useBankIdVerify(pin, secret, {
    reset,
    dispatch,
    LoginStateDispatch,
    BankIdDispatch,
  });

  const formattedAccounts =
    !!accountData &&
    Object.entries(accountData).map(([key, values]: any) => {
      return {
        id: key,
        ...values,
      };
    });

  useEffect(() => {
    if (accountData && formattedAccounts && !formattedAccounts?.length) {
      toast.error(
        "Vi kunde inte verifiera ditt BankID, försök igen senare. (2)",
        {
          id: "form-error-verify",
        }
      );
      LoginStateDispatch({ type: "SET_BANKID_DATA", payload: false });
      LoginStateDispatch({
        type: "SET_BANK_ID_IDENTIFICATION",
        payload: false,
      });
      RegisterDispatch({ type: "SET_VIEW", payload: "bankid" });
      dispatch({ type: "RESET", payload: true });
      return;
    }
  }, [formattedAccounts]);

  console.log("accountData", accountData);

  if (isLoading || !formattedAccounts) {
    return <BeatLoader color="#183e4f" />;
  }

  return (
    <div>
      <h3 className="block text-16 mt-8 mb-4 block font-semibold text-modal-blue">
        Välj ett konto nedan
      </h3>

      <div className={blockClass}>
        {formattedAccounts.map((account: any, index: number) => {
          const buttonProps = account?.accountExists
            ? {
                onClick: () => {
                  loginUser(account?.userId);
                },
                bgColor: "transparent",
                borderColor: "blue",
                textColor: "blue",
                textCenter: true,
                className: "w-fit mx-auto hover:bg-blue hover:text-white",
              }
            : {
                onClick: () => {
                  dispatch({ type: "SET_VIEW", payload: "register" });
                  dispatch({ type: "SET_ACCOUNT", payload: account });
                  mutate(["api/getBankIdinfo", pin, account?.id]);
                },
                textCenter: true,
                className: "w-fit mx-auto",
              };

          return (
            <div
              key={index}
              className="bg-white rounded-xl flex flex-col space-y-2 py-5 border border-light-gray-ultra text-center font-semibold border-transparent first:rounded-t-xl border border-solid relative text-gray-dark hover:shadow-lg transition-all duration-300"
              role="button"
              onClick={() => {}}
            >
              <h3 className="text-modal-blue">{account?.name}</h3>
              <Button {...buttonProps}>
                {account?.accountExists ? "Logga in" : "Registrera"}
                {" som "}
                {account?.type === "privatePerson"
                  ? "  privatperson"
                  : "  företag"}
              </Button>
            </div>
          );
        })}
      </div>

      <Alert
        className="w-fit my-4 bg-aliceblue-dark text-modal-blue"
        variant="outlined"
        icon={
          <svg
            xmlns="http://www.w3.org/2000/svg"
            fill="none"
            viewBox="0 0 24 24"
            strokeWidth={2}
            stroke="currentColor"
            className="h-6 w-6"
          >
            <path
              strokeLinecap="round"
              strokeLinejoin="round"
              d="M11.25 11.25l.041-.02a.75.75 0 011.063.852l-.708 2.836a.75.75 0 001.063.853l.041-.021M21 12a9 9 0 11-18 0 9 9 0 0118 0zm-9-3.75h.008v.008H12V8.25z"
            />
          </svg>
        }
      >
        <h3 className="text-xl mb-2">Vill du registrera ditt företag? </h3>Du
        behöver vara firmatecknare för att kunna genomföra registreringen. Om
        ditt företag inte visas i listan nedan, vänligen{" "}
        <Link href="/om-oss/kontakt" target="_blank">
          kontakta oss
        </Link>{" "}
        så hjälper vi dig vidare.
      </Alert>

      <div
        className="py-6 text-center font-semibold rounded-b-xl relative text-blue hover:text-blue transition-all duration-300 after:absolute selection-none"
        role="button"
        onClick={() => {
          reset();
          LoginStateDispatch({ type: "SET_BAR_VISIBLE", payload: true });
          LoginStateDispatch({
            type: "SET_SELECTED_LINK",
            payload: "bankid",
          });
          LoginStateDispatch({
            type: "SET_BANK_ID_IDENTIFICATION",
            payload: false,
          });
          dispatch({ type: "SET_VIEW", payload: "" });
        }}
      >
        Använd ett annat konto
      </div>
    </div>
  );
};

export default ChooseAccount;
