
export const validatePassword = (password: string) => {
    // Minimum eight characters
    const lengthCheck = password.length >= 8 && password.length <= 120;

    // Check for variety of character types
    const checks = [
        /[A-Z]/.test(password), // Uppercase Letter
        /[a-z]/.test(password), // Lowercase Letter
        /[0-9]/.test(password), // Digit
        /[^A-Za-z0-9]/.test(password), // Special Character
    ];

    // Count how many types of characters are present
    const trueCounts = checks.filter(Boolean).length;

    // Pass if length is sufficient and at least 2 types of characters are present
    const passed = lengthCheck && trueCounts >= 2;

    return passed
};

export const validateUsername = (username: string) => {
    // Check length (up to 50 characters)
    const lengthCheck = username.length >= 6 && username.length <= 24;

    // Ensure username contains only A-Z, 0-9, -, and _
    const patternCheck = /^[A-Za-z0-9äöåÄÖÅ_-]+$/.test(username); // Moved '-' to the end

    // Ensure "tovek" is not present (case insensitive)
    const forbiddenWordCheck = !/tovek/i.test(username);

    // Pass if all checks are true
    return lengthCheck && patternCheck && forbiddenWordCheck;
};
