import { checkPinNumber, pinLogin } from "@/actions/user";
import React, {
  useCallback,
  useContext,
  useEffect,
  useRef,
  useState,
} from "react";
import useSWR from "swr";
import { AuthenticationContext } from "../Wrapper";
import toast from "react-hot-toast";
import classNames from "classnames";
import { signIn } from "next-auth/react";
import { useRouter } from "next/navigation";
import useModalStore from "@/hooks/useModalStore";
import useUserStore from "@/stores/useUserStore";
import { BankIdContext } from "@/components/BankId/BankIdContext";
import BeatLoader from "react-spinners/BeatLoader";
import { getErrorCodeMessage } from "@/lib/reporting";

const BankIdChooseAccount = ({ identification }: { identification: any }) => {
  const { loginModal } = useModalStore();
  const [isActiveSignIn, setIsActiveSignIn] = useState(false);
  const { state, dispatch } = useContext(BankIdContext);
  const { dispatch: LoginStateDispatch } = useContext(AuthenticationContext);
  const { reset } = state || {};
  const router = useRouter();

  const pinNumber = identification?.completionData?.user?.personalNumber || "";
  const secret = identification?.secret;

  const hasLoggedIn = useRef(false);

  const {
    data: users,
    error,
    isLoading,
  } = useSWR<any, Error>(
    ["api/userPinCheck", pinNumber],
    async () => {
      const response = await checkPinNumber(pinNumber);
      return response;
    },
    {
      refreshInterval: 0,
      revalidateOnFocus: false,
    }
  );

  useEffect(() => {
    if (users && users.length === 1 && !hasLoggedIn.current) {
      const user = users[0];
      hasLoggedIn.current = true;

      // Delay login to fgive time for alias to be set
      setTimeout(() => {
        loginUser(user.userId, pinNumber, user.userName);
      }, 1000);
    }
  }, [users]);

  const loginUser = useCallback(
    async (userId: number, userPin: number, username: string) => {
      setIsActiveSignIn(true);
      toast.loading("Loggar in användaren: " + username, {
        id: "loginToast",
      });
      const errorMessage =
        "Kunde inte logga in, försök igen eller pröva att ladda om sidan.";

      try {
        const response = await pinLogin(userPin, userId, secret);

        if (response?.token) {
          signIn("tovek-session", {
            userId: userId,
            token: response?.token,
            secret: secret,
            redirect: false,
          })
            .then((res: any) => {
              if (!res || res?.error) {
                toast.error(
                  getErrorCodeMessage(res?.error, null, errorMessage),
                  {
                    id: "loginToast",
                    duration: 10000,
                  }
                );
                throw new Error("Error signing in");
              }

              toast.success("Du är nu inloggad!", {
                id: "loginToast",
                duration: 5000,
              });

              loginModal.onClose();
              window.location.reload();
            })
            .catch((data: any) => {
              setIsActiveSignIn(false);
            });
        } else {
          toast.error(
            getErrorCodeMessage(
              response?.error?.code,
              null,
              "Din session har gått ut, vänligen logga in igen."
            ),
            {
              id: "loginToast",
              duration: 10000,
            }
          );
          router.refresh();
        }

        //dispatch({ type: "SET_IDENTIFICATION", payload: data });
      } catch (error: any) {
        toast.error(errorMessage, {
          id: "loginToast",
          duration: 10000,
        });
        setIsActiveSignIn(false);
      }
    },
    [secret, router, loginModal]
  );

  useEffect(() => {
    if (error) {
      toast.error(
        "Kunde inte hitta något konto kopplat till ditt personummer.",
        {
          id: "userPinError",
        }
      );

      LoginStateDispatch({ type: "SET_SHOW", payload: "login" });
      LoginStateDispatch({ type: "SET_BAR_VISIBLE", payload: true });
      LoginStateDispatch({
        type: "SET_BANK_ID_IDENTIFICATION",
        payload: false,
      });
    }
  }, [error]);

  if (isActiveSignIn || isLoading || (users && users?.length === 1)) {
    return (
      <div className="flex justify-center items-center w-full">
        <BeatLoader color="#183e4f" />
      </div>
    );
  }

  if (error) {
    return null;
  }

  const loginClass = classNames("rounded-lg flex flex-col space-y-4", {
    "opacity-50 pointer-events-none	": isActiveSignIn,
  });

  return (
    <>
      {users ? (
        <>
          <h3 className="block text-16 mt-8 mb-4 block font-semibold text-modal-blue">
            Välj ett konto nedan
          </h3>

          <div className={loginClass}>
            {users.map((user: any, index: number) => {
              const key = user?.userId ?? `user-${index}`;
              return (
                <div className="relative" key={index}>
                  <div
                    key={key}
                    data-tooltip-id="default-tooltip"
                    data-tooltip-content={
                      user?.userGrantedStatus !== "active"
                        ? "Detta konto är inaktivt, kontakta oss så hjälper vi dig."
                        : null
                    }
                    className={classNames(
                      "bg-white shadow-sm hover:shadow-lg py-6 px-4 text-center font-semibold first:rounded-lg relative text-gray-dark transition-all duration-300 after:absolute after:w-[95%] after:h-[1px] after:bg-gray-light-bg after:left-0 after:right-0 after:bottom-0 last:after:hidden",
                      {
                        "opacity-50 cursor-default hover:!shadow-none cursor-help":
                          user?.userGrantedStatus !== "active",
                      }
                    )}
                    role="button"
                    onClick={() =>
                      user?.userGrantedStatus === "active" &&
                      loginUser(user.userId, pinNumber, user?.userName)
                    }
                  >
                    <div className="w-full h-full relative">
                      {user?.userFullName} ({user?.userName})
                    </div>
                  </div>
                  {user?.userGrantedStatus !== "active" && (
                    <div className="absolute right-0 top-0 text-xs font-bold px-2 py-1 bg-palegoldenrod text-yellow-darker">
                      Inaktivt konto
                    </div>
                  )}
                </div>
              );
            })}
            <div
              className="py-6 text-center font-semibold rounded-lg relative text-blue hover:bg-blue/25 hover:text-blue transition-all duration-300 after:absolute selection-none"
              role="button"
              onClick={() => {
                reset();
                LoginStateDispatch({ type: "SET_BAR_VISIBLE", payload: true });
                LoginStateDispatch({
                  type: "SET_SELECTED_LINK",
                  payload: "bankid",
                });
                LoginStateDispatch({
                  type: "SET_BANK_ID_IDENTIFICATION",
                  payload: false,
                });
                dispatch({ type: "SET_VIEW", payload: "" });
              }}
            >
              Använd ett annat konto
            </div>
          </div>
        </>
      ) : null}
    </>
  );
};

export default BankIdChooseAccount;
