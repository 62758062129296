import React, { useContext, useEffect, useReducer } from "react";
import ChooseAccount from "./views/ChooseAccount";
import RegisterAccount from "./views/CreateAccount";
import RegisterUserBankId from "./views/RegisterUserBankId";
import { AccountContext } from "./context/AccountContext";
import { RegisterContext } from "..";

const Authenticated = ({
  identification,
  bankId,
}: {
  identification: any;
  bankId: any;
}) => {
  const { dispatch: RegisterStateDispatch } = useContext(RegisterContext);

  const [state, dispatch] = useReducer(
    (state: any, action: any) => {
      switch (action.type) {
        case "SET_VIEW":
          return { ...state, view: action.payload };
        case "SET_ACCOUNT":
          return { ...state, account: action.payload };
        case "UPDATE_STATE":
          return { ...state, ...action.payload };
        case "RESET":
          return {
            ...state,
            view: "choose",
            account: null,
            identification: null,
            registrationFields: null,
            secret: null,
          };
        default:
          return state;
      }
    },
    {
      view: "choose",
      account: null,
      secret: null,
      identification,
      registrationFields: null,
      bankId,
    }
  );

  useEffect(() => {
    if (state.view === "choose") {
      RegisterStateDispatch({
        type: "SET_STEP",
        payload: {
          currentStep: 2,
          info: "Val av konto",
        },
      });
    } else if (state.view === "register") {
      RegisterStateDispatch({
        type: "SET_STEP",
        payload: {
          currentStep: 3,
          info: "Fyll i dina uppgifter",
        },
      });
    } else if (state.view === "register-bankid") {
      RegisterStateDispatch({
        type: "SET_STEP",
        payload: {
          currentStep: 3,
          info: "Slutför registreringen genom att signera registreringsavtalet",
        },
      });
    }
  }, [state.view]);

  return (
    <AccountContext.Provider value={{ state, dispatch }}>
      {state.view == "register" && <RegisterAccount />}
      {state.view == "choose" && <ChooseAccount />}
      {state.view == "register-bankid" && <RegisterUserBankId />}
    </AccountContext.Provider>
  );
};

export default Authenticated;
