"use client";

import React, { useEffect } from "react";
import LoginWrapper from "./login";
import RegisterWrapper from "./register";
import ForgotPasswordWrapper from "./ForgotPassword";
import classNames from "classnames";
import { useSearchParams } from "next/navigation";
import useModalStore from "@/hooks/useModalStore";

export const AuthenticationContext = React.createContext<any>(null);

export const MaskPasswordIcon: React.FC<{
  isMasked: boolean;
  onClick?: () => void;
  fill?: string;
}> = ({ isMasked, onClick, fill = "#2D2D32" }) => {
  const Visible = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M12 6.5C15.79 6.5 19.17 8.63 20.82 12C19.17 15.37 15.8 17.5 12 17.5C8.2 17.5 4.83 15.37 3.18 12C4.83 8.63 8.21 6.5 12 6.5ZM12 4.5C7 4.5 2.73 7.61 1 12C2.73 16.39 7 19.5 12 19.5C17 19.5 21.27 16.39 23 12C21.27 7.61 17 4.5 12 4.5ZM12 9.5C13.38 9.5 14.5 10.62 14.5 12C14.5 13.38 13.38 14.5 12 14.5C10.62 14.5 9.5 13.38 9.5 12C9.5 10.62 10.62 9.5 12 9.5ZM12 7.5C9.52 7.5 7.5 9.52 7.5 12C7.5 14.48 9.52 16.5 12 16.5C14.48 16.5 16.5 14.48 16.5 12C16.5 9.52 14.48 7.5 12 7.5Z"
        fill={fill}
      />
    </svg>
  );

  const Hide = (
    <svg
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      xmlns="http://www.w3.org/2000/svg"
      className="cursor-pointer"
    >
      <path
        d="M2 5.27L3.28 4L20 20.72L18.73 22L15.65 18.92C14.5 19.3 13.28 19.5 12 19.5C7 19.5 2.73 16.39 1 12C1.69 10.24 2.79 8.69 4.19 7.46L2 5.27ZM12 9C12.7956 9 13.5587 9.31607 14.1213 9.87868C14.6839 10.4413 15 11.2044 15 12C15 12.35 14.94 12.69 14.83 13L11 9.17C11.31 9.06 11.65 9 12 9ZM12 4.5C17 4.5 21.27 7.61 23 12C22.18 14.08 20.79 15.88 19 17.19L17.58 15.76C18.94 14.82 20.06 13.54 20.82 12C19.17 8.64 15.76 6.5 12 6.5C10.91 6.5 9.84 6.68 8.84 7L7.3 5.47C8.74 4.85 10.33 4.5 12 4.5ZM3.18 12C4.83 15.36 8.24 17.5 12 17.5C12.69 17.5 13.37 17.43 14 17.29L11.72 15C10.29 14.85 9.15 13.71 9 12.28L5.6 8.87C4.61 9.72 3.78 10.78 3.18 12Z"
        fill={fill}
      />
    </svg>
  );

  return (
    <div
      onClick={onClick}
      className="cursor-pointer !absolute right-3 top-[50%] translate-y-[-50%]"
    >
      {isMasked ? Visible : Hide}
    </div>
  );
};

interface State {
  show: string;
  isBarVisible: boolean;
  selectedLink: string;
  from: string;
  hideTitles: boolean;
  loginMethod: string;
  bankIdVerification: any;
  isLoginModal: boolean;
}

interface AuthenticationProps {
  initialProps?: object;
  children?: React.ReactNode;
  from?: string;
}

export const Authentication: React.FC<AuthenticationProps> = ({
  initialProps = {},
  from = "",
}) => {
  const searchParams = useSearchParams();

  const searchParamsValues = React.useMemo(() => {
    return {
      shouldCreateAccount: typeof searchParams.get("skapa-konto") === "string",
      shouldLogin: typeof searchParams.get("logga-in") === "string",
      shouldRecover: typeof searchParams.get("glomt-losenord") === "string",
    };
  }, [searchParams]);

  const { shouldCreateAccount, shouldLogin, shouldRecover } =
    searchParamsValues;

  const reducer = (state: any, action: any) => {
    switch (action.type) {
      case "SET_BAR_VISIBLE":
        return { ...state, isBarVisible: action.payload };
      case "SET_SELECTED_LINK":
        return { ...state, selectedLink: action.payload };
      case "SET_SHOW":
        return { ...state, show: action.payload };
      case "SET_HIDE_TITLES":
        return { ...state, hideTitles: action.payload };
      case "SET_LOGIN_TITLE":
        return { ...state, loginTitle: action.payload };
      case "SET_LOGIN_DESCRIPTION_TEXT":
        return { ...state, loginDescriptionText: action.payload };
      case "SET_LOGIN_METHOD":
        return { ...state, loginMethod: action.payload };
      case "SET_BANK_ID_IDENTIFICATION":
        return { ...state, bankIdVerification: action.payload };
      default:
        return state;
    }
  };

  const [state, dispatch] = React.useReducer<React.Reducer<State, any>>(
    reducer,
    {
      show: "login",
      from: "page",
      isBarVisible: true,
      loginMethod: "bankid",
      selectedLink: "email",
      bankIdVerification: false,
      isLoginModal: false,
      hideTitles: false,
      ...initialProps,
    }
  );

  const show = state?.show;

  const DisplayComponent = () => {
    if (show === "login" || show == "" || from === "login") {
      return <LoginWrapper />;
    } else if (show === "register") {
      return <RegisterWrapper />;
    } else if (show === "reset") {
      return <ForgotPasswordWrapper />;
    }

    return null;
  };

  const wrapperClass = classNames("w-full", {
    "max-w-md mx-auto": show === "login" || show == "",
    "max-w-lg mx-auto": show === "register",
  });

  useEffect(() => {
    if (shouldCreateAccount) {
      dispatch({ type: "SET_SHOW", payload: "register" });
    }
  }, [shouldCreateAccount]);

  useEffect(() => {
    if (shouldLogin) {
      dispatch({ type: "SET_SHOW", payload: "login" });
    }
  }, [shouldLogin]);

  useEffect(() => {
    if (shouldRecover) {
      dispatch({ type: "SET_SHOW", payload: "reset" });
    }
  }, [shouldRecover]);

  return (
    <AuthenticationContext.Provider value={{ state, dispatch }}>
      <div className={wrapperClass}>{<DisplayComponent />}</div>
    </AuthenticationContext.Provider>
  );
};
