import Input from "@/components/Elements/Input";
import { signIn } from "next-auth/react";
import { useContext, useRef, useState } from "react";
import { FieldValues, SubmitHandler, useForm } from "react-hook-form";
import toast from "react-hot-toast";
import { AuthenticationContext, MaskPasswordIcon } from "../Wrapper";
import Button from "@/components/Elements/Button";
import { useRouter } from "next/navigation";
import useModalStore from "@/hooks/useModalStore";
import { getErrorCodeMessage } from "@/lib/reporting";
import Link from "next/link";

export default function LoginUsername() {
  const { state, dispatch } = useContext(AuthenticationContext);
  const { loginModal } = useModalStore();

  const formRef = useRef<HTMLFormElement>(null);

  const [isLoading, setIsLoading] = useState(false);
  const [isPasswordMasked, setIsPasswordMasked] = useState(true);

  const router = useRouter();

  const {
    trigger,
    register,
    handleSubmit,
    watch,
    formState: { errors },
  } = useForm<FieldValues>({
    defaultValues: {
      username: "",
      password: "",
    },
  });

  const onSubmit: SubmitHandler<FieldValues> = (
    data,
    e?: React.BaseSyntheticEvent
  ) => {
    e?.preventDefault();

    setIsLoading(true);

    const errorMessage =
      "Kunde inte logga in, försök igen eller pröva att ladda om sidan.";

    signIn("tovek-auth", {
      ...data,
      redirect: false,
    })
      .then((res: any) => {
        setIsLoading(false);

        if (!res || res?.error) {
          toast.error(getErrorCodeMessage(res?.error, null, errorMessage));
          throw new Error("Error signing in");
        }

        if (res?.ok) {
          toast.success("Du är nu inloggad!");
          loginModal.onClose();
          window.location.reload();
        }
      })
      .catch((data) => {
        setIsLoading(false);
      });
  };

  const maybeSubmit = (e: React.KeyboardEvent<HTMLInputElement>) => {
    if (e.key === "Enter") {
      formRef.current?.dispatchEvent(
        new Event("submit", { bubbles: true, cancelable: true })
      );
    }
  };

  const hasErrors = Object.keys(errors).length !== 0;

  return (
    <form ref={formRef} onSubmit={handleSubmit(onSubmit)}>
      <div className="flex flex-col gap-4">
        <Input
          id="username"
          label="username"
          type="text"
          placeholder="Användarnamn"
          inputClassName="!text-base py-4 px-5"
          disabled={isLoading}
          error={errors.username !== undefined}
          onKeyUp={(e) => maybeSubmit(e)}
          {...register("username", {
            required: "Ange ditt användarnamn",
            // pattern: {
            //   value: /^[A-Z0-9._%+-]+@[A-Z0-9.-]+\.[A-Z]{2,}$/i,
            //   message: "Felaktig e-postadress",
            // },
          })}
        />
        {errors.username && (
          <span className="text-red-400 text-12 !-my-2">
            {errors.username.message?.toString()}
          </span>
        )}

        <div className="flex relative">
          <Input
            id="password"
            label="Password"
            type={isPasswordMasked ? "password" : "text"}
            placeholder="Lösenord"
            disabled={isLoading}
            inputClassName="!text-base py-4 px-5"
            error={errors.password !== undefined}
            onKeyUp={(e) => maybeSubmit(e)}
            {...register("password", { required: "Ange ditt lösenord" })}
          />
          <MaskPasswordIcon
            isMasked={isPasswordMasked}
            fill="#0a5599"
            onClick={() => setIsPasswordMasked((prev) => !prev)}
          />
        </div>
        {errors.password && (
          <span className="text-red-400 text-12 !-my-2">
            {errors.password.message?.toString()}
          </span>
        )}
      </div>

      <Link
        onClick={() => loginModal.onClose()}
        href="/mina-sidor/?glomt-losenord"
        className="text-16 block w-fit ml-auto cursor-pointer text-blue font-semibold mt-3 mb-6"
      >
        Glömt lösenord?
      </Link>

      <div className="flex flex-row space-x-3">
        {state.from == "modal" && (
          <Button
            bgColor="transparent"
            textColor="blue"
            borderColor="blue"
            onClick={() => loginModal.onClose()}
          >
            Avbryt
          </Button>
        )}
        <Button
          onClick={handleSubmit(onSubmit)}
          disabled={isLoading || hasErrors}
          bgColor="blue"
          isLoading={isLoading}
        >
          Logga in
        </Button>
      </div>
    </form>
  );
}
